import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { desarrollosJson } from "../desarrollo";
import axios from 'axios'

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import "./Desarrollos.css";

import bed from '../img/icons/bed.png'
import land from '../img/icons/land.png'
import build from '../img/icons/build.png'
import parking from '../img/icons/parking.png'
import bath from '../img/icons/bath.png'
import { ArrowDownCircle, ArrowRight } from "react-bootstrap-icons";

const EASY_PATH = "https://inmobiplace.com/srv/properties.do";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

function Desarrollos() {
  const [data, setData] = useState([]);

  const [ properties, setProperties ] = useState([])
  const [ original, setOriginal ] = useState([])
  const [ propertyTypeList, setPropertyTypeList ] = useState([])
  const [ categoryList, setCategoryList ] = useState([])
  const [ mainPropertyList, setMainPropertyList ] = useState(null)

  const [ propertyType, setPropertyType ] = useState(urlParams.get('ptype'))
  const [ category, setCategory ] = useState(urlParams.get('offer'))
  const [ propertyTypes, setPropertyTypes ] = useState([])
  const [ categories, setCategories ] = useState([])

  const [ minPrice, setMinPrice ] = useState(null)
  const [ maxPrice, setMaxPrice ] = useState(null)
  const [ baths, setBaths ] = useState(0)
  const [ beds, setBeds ] = useState(0)
  const [ parkings, setParkings ] = useState(0)
  const [ search, setSearch ] = useState("")

  const [checkedState, setCheckedState] = useState(null)
  const [ checkedCatState, setCheckedCatState ] = useState(null)

  useEffect(() => {
    handleOnSearchProperties()
  }, []);

  /*useEffect(() => {
    onChangeFilter()
  }, [ propertyType, category, minPrice, maxPrice, beds, baths, parkings, search ]);*/
  
  const handleOnSearchProperties = () => {
    var pType = [];
    var cat = []

    axios.post(EASY_PATH, {}, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'aW5tb2JpQW5kbWlu',
          'Access-Control-Allow-Origin': '*'
        }
      }).then(function(response) {
        var newList = []
        var mainList = []
        console.log("success", response.data.content)

        response.data.content.map(item => {
          cat.indexOf(item.operations[0].type) === -1 ? cat.push(item.operations[0].type) : console.log(".");
          pType.indexOf(item.property_type) === -1 ? pType.push(item.property_type) : console.log(".");

          item.sort = 100

          //newList.push(item)
          mainList.push( item )
        })

        mainList.map( item => {
          var elements = [];
          if(item.bedrooms) elements.push( 'beds' );
          if(item.bathrooms) elements.push( 'baths' );
          if(item.parking_spaces) elements.push( 'parking' );
          if(item.construction_size > 0) elements.push( 'build' );
          if(item.lot_size > 0) elements.push( 'land' );

          item.hightlights = elements;
        })

        console.log( "original", mainList )
        newList = mainList

        var propTypeParam = urlParams.get('ptype')
        var catParam = urlParams.get('offer')

        if( propTypeParam ) {
          var propsTemp = []
          propsTemp.push( propTypeParam )
          setPropertyTypes( propsTemp )

          newList = newList.filter(function(item) { return item.property_type === propTypeParam; });
        } if ( catParam ) {
          var cateTemp = []
          cateTemp.push( catParam )
          setCategories( cateTemp )

          newList = newList.filter(function(item) { return item.operations[0].type === catParam; });
        }

        newList = newList.sort((a,b) => a.sort - b.sort);
        pType = pType.sort()
        
        var catChecked = [], ptypeChecked = []

        pType.map( (item, index) => {
          if( item == urlParams.get('ptype') ) ptypeChecked.push( true )
          else ptypeChecked.push( false )
        })

        cat.map( (item, index) => {
          if( item == urlParams.get('offer') ) catChecked.push( true )
          else catChecked.push( false )
        })

        setCheckedState( ptypeChecked )
        setCheckedCatState( catChecked )
        setCategoryList(cat)
        setPropertyTypeList(pType)
        setProperties(newList)
        setOriginal(mainList)

      }).catch(function(err) {
        console.log("error", err)
      });
  }

  const onChangeFilter = () => {
    var propsResult = []

    if(propertyTypes.length > 0) {
      propertyTypes.map( prop => {
        propsResult.push(...(original.filter(function(item) { return item.property_type === prop; })))
      })
    } else propsResult = original

    var catResult = []

    if(categories.length > 0) {
      categories.map( cat => {
        catResult.push(...(propsResult.filter(function(item) { return item.operations[0].type === cat; })))
      })
    } else catResult = propsResult

    var priceResult = []

    if( (minPrice && minPrice) > 0 || (maxPrice && maxPrice > 0) ) {
      var min = 0, max = 0
      if( !minPrice ) min = 0
      else min = minPrice

      if( !maxPrice ) max = 10000000000000
      else max = maxPrice

      priceResult.push(...(catResult.filter(function(item) { return item.operations[0].amount >= min && item.operations[0].amount <= max; })))

    } else priceResult = catResult

    var bedsResult = []

    if( beds > 0 ) {
      bedsResult.push(...(priceResult.filter(function(item) { return item.bedrooms >= beds })))
    } else bedsResult = priceResult

    var bathsResult = []

    if( baths > 0 ) {
      bathsResult.push(...(bedsResult.filter(function(item) { return item.bathrooms >= baths })))
    } else bathsResult = bedsResult

    var parkingsResult = []

    if( parkings > 0 ) {
      parkingsResult.push(...(bathsResult.filter(function(item) { return item.bedrooms >= parkings })))
    } else parkingsResult = bathsResult

    var searchText = []

    if( search != "" ) {
      searchText.push(...(parkingsResult.filter(function(item) { return ( (item.title).toUpperCase().includes(search.toUpperCase()) ) || ( (item.location).toUpperCase().includes(search.toUpperCase()) ) })))
    } else searchText = parkingsResult



    setProperties(searchText)
  }

  const handleOnChangeProperty = (e, position) => {
    const temp = propertyTypes

    if( propertyTypes.includes( e.target.name ) ) {
      const index = temp.indexOf( e.target.name )
      temp.splice(index, 1)
    } else {
      temp.push( e.target.name )
    } 

    const updatedCheckedState = checkedState.map((item, index) => index === position ? !item : item);

    setCheckedState(updatedCheckedState);
    setPropertyTypes( temp )
    setPropertyType( Math.random() )
  }

  const handleOnChangeCategory = (e, position) => {
    const temp = categories

    if( categories.includes( e.target.name ) ) {
      const index = temp.indexOf( e.target.name )
      temp.splice(index, 1)
    } else {
      temp.push( e.target.name )
    }

    const updatedCheckedCatState = checkedCatState.map((item, index) => index === position ? !item : item);

    setCheckedCatState(updatedCheckedCatState);
    setCategories( temp )
    setCategory( Math.random() )
  }

  const handleOnChangeBeds = ( value ) => {
    var bedrooms = beds
    if( bedrooms == 0 && value < 0 ) bedrooms = 0
    else bedrooms += value

    setBeds( bedrooms )
  }

  const handleOnChangeBaths = ( value ) => {
    var bathsrooms = baths
    if( bathsrooms == 0 && value < 0 ) bathsrooms = 0
    else bathsrooms += value

    setBaths( bathsrooms )
  }

  const handleOnChangeParkings = ( value ) => {
    var parkingSpaces = parkings
    if( parkingSpaces == 0 && value < 0 ) parkingSpaces = 0
    else parkingSpaces += value

    setParkings( parkingSpaces )
  }

  return (
    <>
      <Container className="properties-container">
        <Row>
          <Col md={ 3 }>
            <br/><br/>
            <div className="filter-container">
              <Container>
                {/* <Row>
                  <Col>
                    <div align="center"> <Button className="submit-btn">Agregar Ubicación</Button>{' '} </div>
                  </Col>
                </Row> */}
                <Row className="filter-banner align-items-center">
                  <Col>
                    <div> SOLICITAR INFORMACIÓN</div>
                  </Col>
                  <Col lg={ 12 } md={ 12 } xs={ 12 }>
                    <ArrowDownCircle />
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <div className="filter-title">Busqueda por localidad</div>
                    <Form.Control className="input-filter" value={ search } size="sm" type="text" placeholder="Ej. CDMX" onChange={ (e) => setSearch( e.target.value )}/>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <div className="filter-title">Tipos de Propiedad</div>
                </Row>
                <Row>
                  <Col>
                    { propertyTypeList.map( (item, index) => (
                      <Form.Check // prettier-ignore
                        type='checkbox'
                        label={ item }
                        name={ item }
                        checked={ checkedState[index] }
                        onChange={ (e) => handleOnChangeProperty(e, index) }
                      />
                    ))}
                  </Col>
                </Row>
                <br/>
                <Row>
                  { categoryList.map( (item, index)  => (
                    <Col md={ 6 }>
                      <Form.Check // prettier-ignore
                        type='checkbox'
                        label={ item == 'sale' ? 'Venta' : 'Renta' }
                        name={ item }
                        checked={ checkedCatState[index] }
                        onChange={ (e) => handleOnChangeCategory(e, index) }
                      />
                    </Col>
                  ))}
                </Row>
                <br/>
                <div className="filter-title">Precio MXN$</div>
                <Row>
                  <Col md={ 6 } style={{ padding: 0 }}>
                    <Form.Group>
                      <Form.Label>Min.</Form.Label>
                      <Form.Control className="input-filter" value={ minPrice } size="sm" type="number" placeholder="$" onChange={ (e) => setMinPrice( e.target.value )}/>
                    </Form.Group>
                  </Col>
                  <Col md={ 6 } style={{ padding: 0 }}>
                    <Form.Group>
                      <Form.Label>Max.</Form.Label>
                      <Form.Control className="input-filter" value={ maxPrice } size="sm" type="number" placeholder="$" onChange={ (e) => setMaxPrice( e.target.value )}/>
                    </Form.Group>
                  </Col>
                </Row>
                <br/>
                <div className="filter-title">Características</div>
                <Row>
                  <Col md={ 12 }>
                    <div>
                      <span className="sum-control" onClick={ () => handleOnChangeBeds(-1) }> - </span>&nbsp;&nbsp;
                      <span>{ beds }</span>&nbsp;&nbsp;
                      <span className="sum-control" onClick={ () => handleOnChangeBeds(1) }> + </span>&nbsp;&nbsp;&nbsp;
                      <span>Recámaras</span>
                    </div>
                    <div>
                      <span className="sum-control" onClick={ () => handleOnChangeBaths(-1) }> - </span>&nbsp;&nbsp;
                      <span>{ baths }</span>&nbsp;&nbsp;
                      <span className="sum-control" onClick={ () => handleOnChangeBaths(1) }> + </span>&nbsp;&nbsp;&nbsp;
                      <span>Baños</span>
                    </div>
                    <div>
                      <span className="sum-control" onClick={ () => handleOnChangeParkings(-1) }> - </span>&nbsp;&nbsp;
                      <span>{ parkings }</span>&nbsp;&nbsp;
                      <span className="sum-control" onClick={ () => handleOnChangeParkings(1) }> + </span>&nbsp;&nbsp;&nbsp;
                      <span>Estacionamiento</span>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <div align="center">
                      <div className="button-desa" onClick={ () => onChangeFilter() }> <span>BUSCAR</span> </div>
                    </div>
                  </Col>
                </Row>
              </Container>

            </div>
          </Col>
          <Col md={ 9 }>
            <br/><br/>
            {/*<div className="font-title-seccions" style={{ textAlign: 'left' }}>Desarrollos</div>*/}
            <Container>
              { properties.length == 0 && 
                <div className=""> No se encontraron resultados para tu busqueda </div>
              }
              { properties.map((item, index) => (
                <Row key={index} className='align-items-center' style={{ marginBottom: "62px", position: 'relative' }}>
                  <a href={`/desarrollos/${ item.public_id.split("-")[1] }`} style={{ padding: 0 }}>
                    <div className="desarrollo-desc-compl" style={{ backgroundImage: "url(" + item.title_image_full + ")", padding: 0 }}>
                      <div className="desarrollo-descrip-img mask" >
                        <Col className="desarrollo-descrip">{item.title}</Col>
                        <Col className="desarrollo-descrip-tipo">{item.location}</Col>
                        <Col className="desarrollo-descrip-tipo">
                          {item.operations[0].type == 'sale' ? "Venta" : "Renta"}
                        </Col>
                        { ( item.hightlights.length > 1 ) &&
                          <Col className="desarrollo-descrip-costo">{ item.operations[0].formatted_amount + ' ' + item.operations[0].currency }</Col>
                        }
                        
                        { ( item.hightlights.length > 2 ) && 
                          <div className="desarrollo-descrip-bg">
                            <Row className="align-items-center">
                              <Col md={ 9 } className='desarrollo-text-line'>
                                <Container>
                                  {/* <Row>
                                    { item.bedrooms &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ bed } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bedrooms } recámaras</span></div> </Col> }
                                    { item.bathrooms &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ bath } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bathrooms } baños</span></div> </Col> }
                                    { item.parking_spaces &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ parking } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.parking_spaces } estacionamientos</span></div> </Col> }
                                    { item.construction_size > 0 &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ build } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.construction_size } m² de construcción</span></div> </Col> }
                                    { item.lot_size > 0 &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ land } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.lot_size } m² de terreno</span></div> </Col> }
                                  </Row> */}
                                  { item.hightlights.length == 4 &&
                                    <Row >
                                      { item.hightlights.includes('beds') &&  <Col md={ 6 } className={ ( item.hightlights.indexOf('beds') != 2 && item.hightlights.indexOf('beds') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ bed } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bedrooms } recámaras</span></div> </Col> }
                                      { item.hightlights.includes('baths') &&  <Col md={ 6 } className={ ( item.hightlights.indexOf('baths') != 2 && item.hightlights.indexOf('baths') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ bath } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bathrooms } baños</span></div> </Col> }
                                      { item.hightlights.includes('parking') &&  <Col md={ 6 } className={ ( item.hightlights.indexOf('parking') != 2 && item.hightlights.indexOf('parking') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ parking } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.parking_spaces } estacionamientos</span></div> </Col> }
                                      { item.hightlights.includes('build') &&  <Col md={ 6 } className={ ( item.hightlights.indexOf('build') != 2 && item.hightlights.indexOf('build') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ build } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.construction_size } m² de construcción</span></div> </Col> }
                                      { item.hightlights.includes('land') &&  <Col md={ 6 } className={ ( item.hightlights.indexOf('land') != 2 && item.hightlights.indexOf('land') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ land } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.lot_size } m² de terreno</span></div> </Col> }
                                    </Row>
                                  }
                                  { (item.hightlights.length == 5 || item.hightlights.length == 3) &&
                                  <Row >
                                    { item.hightlights.includes('beds') &&  <Col md={ 4 } className={ ( item.hightlights.indexOf('beds') != 3 && item.hightlights.indexOf('beds') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ bed } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bedrooms } recámaras</span></div> </Col> }
                                    { item.hightlights.includes('baths') &&  <Col md={ 4 } className={ ( item.hightlights.indexOf('baths') != 3 && item.hightlights.indexOf('baths') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ bath } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bathrooms } baños</span></div> </Col> }
                                    { item.hightlights.includes('parking') &&  <Col md={ 4 } className={ ( item.hightlights.indexOf('parking') != 3 && item.hightlights.indexOf('parking') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ parking } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.parking_spaces } estacionamientos</span></div> </Col> }
                                    { item.hightlights.includes('build') &&  <Col md={ 4 } className={ ( item.hightlights.indexOf('build') != 3 && item.hightlights.indexOf('build') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ build } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.construction_size } m² de construcción</span></div> </Col> }
                                    { item.hightlights.includes('land') &&  <Col md={ 4 } className={ ( item.hightlights.indexOf('land') != 3 && item.hightlights.indexOf('land') != 0 ) ? "item-col item-col-border" : "item-col" }> <div> <img src={ land } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.lot_size } m² de terreno</span></div> </Col> }
                                    { item.hightlights.length == 5 && <Col md={ 4 } className="item-col item-col-border"></Col> }
                                  </Row>
                                  }
                                </Container>
                              </Col>
                              <Col md={ 3 } className='desarrollo-text-line'>
                                <div className="align-btn">
                                  <div className="button-desa"> <span>Conocer más</span> <ArrowRight /> </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        }

                        { ( item.hightlights.length > 0 && item.hightlights.length < 3 ) && 
                          <div className="desarrollo-descrip-bg-v2">
                            <Row className="align-items-center">
                              <Col md={ 8 } xs={ 12 } className='desarrollo-text-line'>
                                <Container className="desarrollo-descrip-bg-v3">
                                  <Row>
                                    { ( item.hightlights.length == 1 ) &&
                                      <>
                                        <div className="price-principal">{ item.operations[0].formatted_amount + ' ' + item.operations[0].currency }</div>
                                      </>
                                    }

                                    { item.hightlights.length > 1 &&
                                      <>
                                        { item.bedrooms &&  <Col md={ 6 } className={item.hightlights.indexOf('beds') != 0 ? "item-col item-col-border" : "item-col" }> <div> <img src={ bed } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bedrooms } recámaras</span></div> </Col> }
                                        { item.bathrooms &&  <Col md={ 6 } className={item.hightlights.indexOf('baths') != 0 ? "item-col item-col-border" : "item-col" }> <div> <img src={ bath } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bathrooms } baños</span></div> </Col> }
                                        { item.parking_spaces &&  <Col md={ 6 } className={item.hightlights.indexOf('parking') != 0 ? "item-col item-col-border" : "item-col" }> <div> <img src={ parking } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.parking_spaces } estacionamientos</span></div> </Col> }
                                        { item.construction_size > 0 &&  <Col md={ 6 } className={item.hightlights.indexOf('build') != 0 ? "item-col item-col-border" : "item-col" }> <div> <img src={ build } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.construction_size } m² de construcción</span></div> </Col> }
                                        { item.lot_size > 0 &&  <Col md={ 6 } className={item.hightlights.indexOf('land') != 0 ? "item-col item-col-border" : "item-col" }> <div> <img src={ land } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.lot_size } m² de terreno</span></div> </Col> }
                                      
                                        {/* { item.bedrooms &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ bed } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bedrooms } recámaras</span></div> </Col> }
                                        { item.bathrooms &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ bath } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.bathrooms } baños</span></div> </Col> }
                                        { item.parking_spaces &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ parking } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.parking_spaces } estacionamientos</span></div> </Col> }
                                        { item.construction_size > 0 &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ build } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.construction_size } m² de construcción</span></div> </Col> }
                                        { item.lot_size > 0 &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ land } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ item.lot_size } m² de terreno</span></div> </Col> } */}
                                      </>
                                    }
                                  </Row>
                                </Container>
                              </Col>
                              <Col md={ 4 } xs={ 12 } className='desarrollo-text-line'>
                                <div className="align-btn">
                                  <div className="button-desa"> <span>Conocer más</span> <ArrowRight /> </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        }
                      </div>
                    </div>
                  </a>
                  <br />
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
      <br/><br/>
    </>
  );
}

export default Desarrollos;
